.breadcrumb {
	background: transparent;
	align-items: center;
	margin: 0 0 1.25rem;
	padding: 0;
	display: block;
	color: #a7a9ab;
	font-size: 12px;
	i {
		font-size: 16px;
	}

	a {
		color: #a7a9ab;
	}

	h1 {
		font-size: 1.5rem;
		line-height: 1;
		padding-right: 0.5rem;
		margin: 0;
		font-weight: normal;
		font-size: 24px;
		a {
			color: #1f1f1f;
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			display: inline-block;
			position: relative;
			padding: 0 0.5rem;
			line-height: 1;
			vertical-align: bottom;
			color: $gray-600;
			&:after {
				position: absolute;
				top: -1px;
				right: 0;
				content: '';
				height: 16px;
				width: 1px;
				background: $gray-600;
				border-radius: 5px;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
			a {
				color: $heading;
			}
		}
	}
}

[dir='rtl'] {
	.breadcrumb {
		h1 {
			padding-right: 0;
			padding-left: 0.5rem;
		}
	}
}
