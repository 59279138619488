.history {
	margin-top: 1rem;
	margin-bottom: 1rem;
	.historic-list {
		list-style-type: none;
		padding-left: 20px;
		border-left: 2px solid $primary;
		margin-left: 15px;
		position: relative;
		&:before {
			content: '';
			width: 2px;
			height: 2px;
			position: absolute;
			left: -2px;
			top: 2px;
			background: $white;
		}
		&:after {
			content: '';
			width: 2px;
			height: 2px;
			position: absolute;
			left: -2px;
			bottom: 2px;
			background: $white;
		}
		li {
			padding: 16px;
			position: relative;
			font-size: 1rem;
			color: $dark1;
			small {
				font-size: 10px;
				color: $grey1;
			}
			&:before {
				position: absolute;
				top: 50%;
				margin-top: -5px;
				left: -26px;
				content: '';
				width: 10px;
				height: 10px;
				border-radius: 5px;
				background-color: $primary;
			}
		}
	}
}
