@import './theme';
@import './form';
@import './snackbar';

.mat-icon {
	color: #aeb0b3;
}

.mat-paginator-navigation-next,
.mat-paginator-navigation-previous {
	&:disabled {
		background: none !important;
	}
}

.mat-tab-label {
	opacity: 1!important;
	color: #57A1A1;
	font-weight: normal;
	padding: 0!important;
	margin-right: 24px;
}

.mat-tab-label-active {
	font-weight: 600;
}

.mat-tab-group.mat-primary .mat-ink-bar {
	background-color: #57A1A1;
	height: 3px;
}

.mat-form-field-label-wrapper {
	top: -1.2em !important;
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
	width: 1.5em !important;
	color: #aeb0b3;
}

.mat-radio-outer-circle {
	border-color: #dddd;
}

.mat-dialog-container {
	max-height: 85vh;
}

.select-outline-address {
	margin-bottom: 16px;
	.mat-form-field {
		line-height: 1.4;
		width: 100%;
		height: 56px;

		.mat-form-field-label-wrapper {
			padding: 0;

			.mat-form-field-label {
				font-size: 14px;
				transform: translateY(-0.39em);
				color: #2C2C2C;
				top: 0.9em;
			}
		}
		.mat-select-trigger {
			font-size: 12px;
		}
		.mat-select-value,
		.mat-select-placeholder {
			color: #696561;
		}
		.mat-form-field-wrapper {
			padding: 0;
		}
		.mat-form-field-flex {
			padding: 28px 16px 8.5px;
			background-color: #FFFFFF;
			border: 1px solid #DAD7D3;
			border-radius: 4px;
		}
		.mat-form-field-infix {
			padding: 0;
			border-top: 0;
		}
		.mat-form-field-underline {
			display: none;
		}
		.mat-select-arrow-wrapper {
			transform: translateY(-0.9em);

			.mat-select-arrow {
				color: #B4AEA6;
			}
		}
	}
}

.option-outline-address {
    height: auto!important;

    .clinic {
        .address {
            font-size: 14px;
            color: #2C2C2C;
            line-height: 1;
            padding: 9px 0 2.5px;
        }
        .name {
            font-size: 12px;
            color: #696561;
            line-height: 1;
            padding: 2.5px 0 9px;
        }
    }
}