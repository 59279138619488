.ng2FileDrop {
	background: #fff;
	border: dotted 1px #b3b3b3;
	border-radius: 4px;
	padding: 40px 0;
	text-align: center;
	transition: all 0.3s ease-in-out;

	.icon {
		color: #d4d4d4;
		display: block;
		font-size: 48px;
	}

	em {
		display: block;
		font-style: normal;
		color: #b3b3b3;
		font-size: 14px;
		padding: 10px 0;
	}

	small {
		display: block;
		font-style: normal;
		color: #b3b3b3;
		font-size: 12px;
	}

	button {
		.icon {
			color: #d4d4d4;
			display: block;
			font-size: 24px;
		}

		input {
			cursor: pointer;
			height: 100%;
			left: 0;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 5;
		}
	}

	&:hover {
		background: #ededed;
		border-color: $brand;
	}

	&:hover strong {
		color: #424242;
	}

	button {
		input {
			cursor: pointer;
			height: 100%;
			left: 0;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 5;
		}

		&:hover {
			background: $branded1;
			border-color: $branded1;
			color: #fff;
		}
	}

	&.has-file {
		background: rgba($brand, 0.05);
		border-color: $brand;

		.icon,
		em {
			color: $brand;
		}

		i.icon {
			animation: upDown 1.4s ease-in-out infinite;
		}
	}

	&-img {
		border-radius: 4px;
		border: 1px dotted #b3b3b3;
		display: block;
		height: auto;
		padding: 16px 0 0 0;
		position: relative;
		text-align: center;
		transition: all 0.3s ease-in-out;
		width: 100%;

		.icon {
			color: #b3b3b3;
			font-size: 24px;
			transition: all 0.3s ease-in-out;
		}

		em {
			color: #b3b3b3;
			display: block;
			font-size: 14px;
			font-style: normal;
			text-transform: uppercase;
			transition: all 0.3s ease-in-out;
		}

		input {
			cursor: pointer;
			height: 100%;
			left: 0;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 5;
		}

		&:hover {
			background: $brand;

			.icon,
			em {
				color: #fff;
			}
		}
	}
}

.text-info-upload {
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #a7a9ab;
	margin-top: 6px;
	margin-left: 10px;
}

.list-upload-file {
	width: 100%;
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		border-top: 1px solid #f7f7f7;
		padding: 12px 0px;

		&:last-child {
			border-bottom: 1px solid #f7f7f7;
		}

		.actions {
			padding: 0px;
			button {
				cursor: pointer;
				background: transparent;
				border: none;
				i {
					font-size: 24px;
					font-weight: normal;
					font-style: normal;
					font-stretch: normal;
					line-height: normal;
					letter-spacing: normal;
					text-align: center;
					color: #aeb0b3;
				}
			}
		}

		.name {
			font-size: 16px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #262626;

			&.loading {
				font-size: 16px;
				font-weight: normal;
				font-style: italic;
				font-stretch: normal;
				line-height: 1.5;
				letter-spacing: normal;
				color: #aeb0b3;
			}
		}
	}
}

.image-item {
	position: relative;
	button {
		position: absolute;
		top: 5px;
		right: 5px;
		&.icon-button {
			width: 22px;
			height: 22px;
			line-height: 21px;
		}
	}
}
