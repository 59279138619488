// SPINNER GLOW
.spinner-glow {
	display: inline-block;
	width: 1em;
	height: 1em;
	background: $gray-300;
	border-radius: 50%;
	margin: 4px auto;
	border: 2px solid transparent;
	animation: glow 1s ease infinite;
}
@keyframes glow {
	0% {
		box-shadow: 0 0 0 0.4em #a1a2a1, 0 0 0 0.1em #a1a2a1;
		transform: rotate(360deg);
	}
	50% {
		border-top-color: #605556;
	}
	100% {
		box-shadow: 0 0 0 0.4em #a1a2a1, 0 0 0 3.6em transparent;
	}
}
@each $name, $value in $theme-colors {
	.spinner-glow-#{$name} {
		background: rgba($value, 0.45);
		animation: glow-#{$name} 1s ease infinite;
	}
}
@each $name, $value in $theme-colors {
	@keyframes glow-#{$name} {
		0% {
			box-shadow: 0 0 0 0.4em rgba($value, 0.45), 0 0 0 0.1em rgba($value, 0.45);
			transform: rotate(360deg);
		}
		50% {
			border-top-color: rgba($value, 0.9);
		}
		100% {
			box-shadow: 0 0 0 0.4em rgba($value, 0.75), 0 0 0 3.6em transparent;
		}
	}
}

// SPINNER
.spinner {
	display: inline-block;
	font-size: 10px;
	margin: auto;
	text-indent: -9999em;
	width: 4em;
	height: 4em;
	border-radius: 50%;
	position: relative;
	-webkit-animation: spin 1.4s infinite linear;
	animation: spin 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
.spinner:before {
	width: 50%;
	height: 50%;
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: '';
}
.spinner:after {
	background: $background;
	width: 75%;
	height: 75%;
	border-radius: 50%;
	content: '';
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

@each $name, $value in $theme-colors {
	.spinner-#{$name} {
		background: $value;
		background: -moz-linear-gradient(left, $value 10%, rgba(67, 236, 76, 0) 42%);
		background: -webkit-linear-gradient(left, $value 10%, rgba(67, 236, 76, 0) 42%);
		background: -o-linear-gradient(left, $value 10%, rgba(67, 236, 76, 0) 42%);
		background: -ms-linear-gradient(left, $value 10%, rgba(67, 236, 76, 0) 42%);
		background: linear-gradient(to right, $value 10%, rgba(67, 236, 76, 0) 42%);
		&:before {
			background: $value;
		}
	}
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

// SPINNER BUBBLE
.spinner-bubble {
	display: inline-block;
	font-size: 8px;
	margin: 30px auto;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	position: relative;
	text-indent: -9999em;
	-webkit-animation: bubble-circle 1.3s infinite linear;
	animation: bubble-circle 1.3s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
@each $name, $value in $theme-colors {
	.spinner-bubble-#{$name} {
		color: $value;
	}
}
@-webkit-keyframes bubble-circle {
	0%,
	100% {
		box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em,
			-3em 0 0 -1em, -2em -2em 0 0;
	}
	12.5% {
		box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
			-2em -2em 0 -1em;
	}
	25% {
		box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
			-2em -2em 0 -1em;
	}
	37.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em,
			-3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	50% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0,
			-3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	62.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0,
			-2em -2em 0 -1em;
	}
	75% {
		box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0,
			-3em 0em 0 0.2em, -2em -2em 0 0;
	}
	87.5% {
		box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0,
			-2em -2em 0 0.2em;
	}
}
@keyframes bubble-circle {
	0%,
	100% {
		box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em,
			-3em 0 0 -1em, -2em -2em 0 0;
	}
	12.5% {
		box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
			-2em -2em 0 -1em;
	}
	25% {
		box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
			-2em -2em 0 -1em;
	}
	37.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em,
			-3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	50% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0,
			-3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	62.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0,
			-2em -2em 0 -1em;
	}
	75% {
		box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0,
			-3em 0em 0 0.2em, -2em -2em 0 0;
	}
	87.5% {
		box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0,
			-2em -2em 0 0.2em;
	}
}

// LOADER-BUBBLE
.loader-bubble,
.loader-bubble:before,
.loader-bubble:after {
	border-radius: 50%;
	width: 2em;
	height: 2em;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: bubble-horz 1.8s infinite ease-in-out;
	animation: bubble-horz 1.8s infinite ease-in-out;
}
.loader-bubble {
	display: inline-block;
	font-size: 6px;
	margin: auto;
	position: relative;
	text-indent: -9999em;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
@each $name, $value in $theme-colors {
	.loader-bubble-#{$name} {
		color: $value;
	}
}
.loader-bubble:before,
.loader-bubble:after {
	content: '';
	position: absolute;
	top: 0;
}
.loader-bubble:before {
	left: -3.5em;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}
.loader-bubble:after {
	left: 3.5em;
}
@-webkit-keyframes bubble-horz {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}
@keyframes bubble-horz {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}