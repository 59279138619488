// .alert {
// 	background: $background;
// 	border-radius: 10px;
// 	.close:focus {
// 		outline: 0;
// 	}
// }
.alert {
	background: $background;
	border-radius: 10px;
	.close:focus {
		outline: 0;
	}

	li {
		font-size: 14px;
	}

	&.alert-warning {
		background-color: #FFF3D5;
		border: solid 1px #FFE7A6;
		color: #7F6018!important;

		p {
			color: #7F6018!important;
		}
		
	}
	&.alert-danger {
		background-color: #FADBDB;
		border: solid 1px #F6B7B7;
		color: #722727!important;

		p {
			color: #722727!important;
		}
	}
	&.alert-success {
		background-color: #E1F4E1;
		border: solid 1px #C3E9C3;
		color: #346234!important;

		p {
			color: #346234!important;
		}
	}
}

// .alert-card {
// 	border: none;
// 	box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $gray-400;
// 	&.alert-success {
// 		box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $success;
// 	}
// 	&.alert-warning {
// 		box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $warning;
// 	}
// 	&.alert-info {
// 		box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $info;
// 	}
// 	&.alert-danger {
// 		box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $danger;
// 	}
// 	&.alert-dark {
// 		box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $gray-600;
// 	}
// }

.alert-card {
	border: none;
	border-radius: 4px;
	font-size: 16px;
	&.alert-success {
		background-color: #E1F4E1;
		border: solid 1px #C3E9C3;
		color: #346234!important;
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

		span i {
			color: #69c669;
		}
	}
	&.alert-warning {
		background-color: #FFF3D5;
		border: solid 1px #FFE7A6;
		color: #7F6018!important;
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

		span i {
			color: #ffc727;
		}
	}
	&.alert-info {
		background-color: #D9E5FB;
		border: solid 1px #B4CBF7;
		color: #264277!important;
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

		span i {
			color: #447eeb;
		}
	}
	&.alert-danger {
		background-color: #FADBDB;
		border: solid 1px #F6B7B7;
		color: #722727!important;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

		span i {
			color: #e54e4e;
		}
	}
	&.alert-dark {
		box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $gray-600;
	}

	span i {
		width: 24px;
		height: 24px;
		margin: 0 8px 0 0;
		padding: 0 2px 0 1px;
		font-size: 24px;
		font-weight: 300;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.21;
		letter-spacing: normal;
		text-align: center;
	}

	button {
		border: solid 1px #dededf;
		background-color: #ffffff;
		padding: 5.5px 9.82px !important;
		border-radius: 50%;
		right: -15px !important;
		top: -14px !important;
		opacity: 1;

		span {
			color: #6f6e76;
		}
	}
}

// NGX TOASTR
// .toast-container {
// 	.toast {
// 		border-radius: 10px;
// 		color: $foreground;
// 		opacity: 1 !important;
// 	}
// 	.toast-close-button {
// 		position: absolute;
// 		color: $gray-400;
// 		top: -1px;
// 		right: 8px;
// 	}
// 	.toast-progress {
// 		height: 2px;
// 		opacity: 1;
// 	}

// 	.toast-success {
// 		background-color: $background;
// 		box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $success;
// 		background-image: url('../../../images/checkmark.svg');
// 		.toast-progress {
// 			background: $success;
// 		}
// 		&:hover {
// 			box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $success;
// 		}
// 	}
// 	.toast-warning {
// 		background-color: $background;
// 		box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $warning;
// 		background-image: url('../../../images/danger.svg');
// 		.toast-progress {
// 			background: $warning;
// 		}
// 		&:hover {
// 			box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $warning;
// 		}
// 	}
// 	.toast-info {
// 		background-color: $background;
// 		box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $info;
// 		background-image: url('../../../images/info.svg');
// 		.toast-progress {
// 			background: $info;
// 		}
// 		&:hover {
// 			box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $info;
// 		}
// 	}
// 	.toast-error {
// 		background-color: $background;
// 		box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $danger;
// 		background-image: url('../../../images/close.svg');
// 		.toast-progress {
// 			background: $danger;
// 		}
// 		&:hover {
// 			box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $danger;
// 		}
// 	}
// }
.toast-container {
	.toast {
		border-radius: 10px;
		color: $foreground;
		opacity: 1 !important;
	}
	.toast-close-button {
		position: absolute;
		color: $gray-400;
		top: -1px;
		right: 8px;
	}
	.toast-progress {
		height: 2px;
		opacity: 1;
	}

	.toast-success {
		background-color: #E1F4E1;
		border: solid 1px #C3E9C3;
		color: #346234!important;
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
		background-image: url('../../../images/checkmark.svg');
		.toast-progress {
			background: $success;
		}
		&:hover {
			box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
		}
	}
	.toast-warning {
		background-color: #FFF3D5;
		border: solid 1px #FFE7A6;
		color: #7F6018!important;
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
		background-image: url('../../../images/danger.svg');
		
		.toast-progress {
			background: $warning;
		}
		&:hover {
			box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
		}
	}
	.toast-info {
		background-color: #D9E5FB;
		border: solid 1px #B4CBF7;
		color: #264277!important;
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
		background-image: url('../../../images/info.svg');
		.toast-progress {
			background: $info;
		}
		&:hover {
			box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
		}
	}
	.toast-error {
		background-color: #FADBDB;
		border: solid 1px #F6B7B7;
		color: #722727!important;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		background-image: url('../../../images/close.svg');
		.toast-progress {
			background: $danger;
		}
		&:hover {
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		}
	}
}
.toast-top-right {
	right: 30px;
}

.toast-container .ngx-toastr {
	color: #000000;
}
