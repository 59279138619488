@import '../variables.scss';

.mat-snack-bar-container {
	border-radius: 4px;
	color: #fff;
	&.error {
		background: $danger;
	}

	&.success {
		background: $success;
	}

	&.warning {
		background: $warning;
	}

	&.info {
		background: $primary;
	}
}
