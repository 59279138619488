@import 'vars';
@import 'header';
@import 'sidebar';
@import 'footer';

#content {
	width: 100%;
	padding: 30px;
	min-height: calc(100vh - #{$topbar-height});
	transition: all 0.3s;
	margin-top: $topbar-height;
	float: right;
	display: flex;
	flex-direction: column;
	margin-left: 0px;
	background: #F4F3F2;
	&.sidebar-active {
		margin-left: $sidebar-width;
	}

	&.layout-toggle-sidebar {
		margin-left: $sidebar-min-width;
	}

	@include media-breakpoint-down(md) {
		&.sidebar-active {
			margin-left: 0px;
		}
	}
}

@media (max-width: 768px) {
  #content {
    padding: 20px 10px !important;
  }
}

.module-loader,
.loader {
	position: fixed;
	background: rgba($background, 0.5);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9;

	display: flex;
    justify-content: center;
    align-items: center;
	.loader,
	.spinner {
		position: fixed;
		// top: 45%;
		// left: calc(50% + 56px);
		z-index: inherit;

		margin: 0;
	}

	.spinner-mobile {
		position: fixed;
		top: 45%;
		left: 50%;
		z-index: inherit;
	}

	.spinner::after {
		background: transparent;
	}
}

.loader-card {
	position: absolute;
	background: rgba($background, 0.5);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
	display: flex;
    justify-content: center;
	align-items: center;

	.spinner {
		position: fixed;
	}
	.spinner-absolute {
		position: absolute;
	}
}
