:root {
	--fc-small-font-size: 0.688rem;
	--fc-page-bg-color: #fff;
	--fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
	--fc-neutral-text-color: #808080;
	--fc-border-color: #dad7d3;

	--fc-button-text-color: #57a1a1;
	--fc-button-bg-color: #f4f3f2;
	--fc-button-border-color: #dad7d3;
	--fc-button-hover-bg-color: #dbdad9;
	--fc-button-hover-border-color: #c4c1bd;
	--fc-button-active-bg-color: #dbdad9;
	--fc-button-active-border-color: #c4c1bd;

	--fc-event-bg-color: #3A9BD9;
	--fc-event-border-color: #3A9BD9;
	--fc-event-text-color: #fff;
	--fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

	// --fc-more-link-bg-color: #d0d0d0;
	--fc-more-link-bg-color: red;
	--fc-more-link-text-color: inherit;

	--fc-event-resizer-thickness: 8px;
	--fc-event-resizer-dot-total-width: 8px;
	--fc-event-resizer-dot-border-width: 1px;

	--fc-non-business-color: rgba(215, 215, 215, 0.3);
	--fc-bg-event-color: rgb(244, 153, 40);
	--fc-bg-event-opacity: 0.2;
	// --fc-bg-event-opacity: 1;
	// --fc-highlight-color: rgba(188, 232, 241, 0.3);
	--fc-highlight-color: rgba(188, 232, 241, 0.4);
	// --fc-today-bg-color: rgba(255, 220, 40, 0.15);
	--fc-today-bg-color: none;
	--fc-now-indicator-color: red;
}

.fc {
	& .fc-button {
		padding: 7px 12px !important;
		font-size: 11px !important;
		display: flex !important;
		align-items: center !important;
		align-content: center !important;
		line-height: 1 !important;
		height: 30px;

		.fc-icon {
			font-size: 16px !important;
		}
	}

	& .fc-button:focus {
		outline: 0;
		box-shadow: none !important;
	}

    & .fc-toolbar-title {
        font-size: 1.5rem!important;
    }

    & .fc-col-header-cell {
	    background-color: #F4F3F2;
	    // border: 1px solid #DAD7D3!important;
	    a.fc-col-header-cell-cushion {
	        color: #2C2C2C;
            font-weight: 500;
            font-size: 14px;

            padding-top: 6px;
            padding-bottom: 4px;
	    }

	    &.fc-day-today {
	        background-color: #57A1A1;

	        a {
	            color: #FFFFFF;
	        }
	    }
	}

    // .fc-timegrid-axis-cushion,
	.fc-timegrid-slot-label-cushion {
	    font-size: 11px;
	}


	.fc-avaiable
  .fc-unavaiable
  .fc-waiting
  .fc-blocked {
		cursor: pointer;
	}

}
