// LAYOUT VARS
$topbar-height: 60px;
$topbar-height-mobile: 70px;

// sidebar background
$sidebar-width: 260px;
$sidebar-min-width: 62px;
$sidebar-box-shadow: 0px 2px 8px #0000001A;
$sidebar-background: #ffffff;
$sidebar-background-hover: transparent;
$sidebar-background-active: #f2f2f2;
$sidebar-border-radius: 2px;

// sidebar color text
$sidebar-text-color: #696561;
$sidebar-text-active-color: #2C2C2C;
$sidebar-text-hover-color: #2C2C2C;

// sidebar color icon
$sidebar-icon-color: #B4AEA6;
$sidebar-icon-active-color: #57A1A1;
$sidebar-icon-hover-color: #57A1A1;
