.avatar-sm {
	width: 36px;
	height: 36px;
}
.avatar-md {
	width: 54px;
	height: 54px;
}
.avatar-lg {
	width: 80px;
	height: 80px;
}
