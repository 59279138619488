// forms
.mat-form-field {
	width: 100%;
	font-size: 16px;
	font-family: 'Poppins', sans-serif;

	input, .mat-form-field-infix {
		cursor: pointer;
	}

	.mat-form-field-label {
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #696561;
	}

	.mat-form-field-underline {
		background-color: #DAD7D3;
	}

	&.mat-form-field-should-float {
		.mat-form-field-label {
		  color: #57A1A1;
		}
	}

	.mat-input-element:disabled {
		color: #636565;
	}

	&.mat-focused {
		.mat-form-field-label {
			color: #57A1A1;
		}
		.mat-form-field-ripple {
			background-color: #57A1A1;
		}
	}

  &.mat-form-field-invalid {
    margin-bottom: 10px;
    .mat-form-field-label {
      color: #e54e4e;
    }
    .mat-form-field-ripple {
      background-color: #e54e4e;
    }
  }

	&.mat-form-field-disabled {
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #2C2C2C;

		.mat-select-value {
			color: #2C2C2C;
		  }

		  input, .mat-form-field-infix {
			cursor: default;
		  }

		.mat-form-field-label {
			font-size: 16px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.45;
			letter-spacing: normal;
			color: #B4AEA6;
		}

		.mat-form-field-infix {
			padding: 0.24em 0 0.375em 0;
		}

		.mat-form-field-underline {
			background-image: linear-gradient(
				to right,
				rgba(210, 214, 214, 1) 0%,
				rgba(210, 214, 214, 1) 50%,
				transparent 0%
			);
		}
	}

	.error {
		display: block;
		color: #ed4646;
	}
}

.mat-checkbox {
	.mat-checkbox-label {
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.56;
		letter-spacing: normal;
		color: #666666;
	}
}

.mat-radio-button {
	.mat-radio-label {
    .mat-radio-label-content {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: #666666;
    }
	}
}

.mat-tooltip {
	&.error {
		background: #ed4646;
		color: $white;
	}
}

label {
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.27;
	letter-spacing: normal;
	color: #3a4a5b;
}

.mat-radio-label-content {
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #262626;
}

.mat-error {
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.45;
	letter-spacing: normal;
	color: #e54e4e;
}
