.card {
	// padding: 1rem;
	border-radius: 4px;
	box-shadow: 0px 2px 8px #0000001A;
	// box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
	// box-shadow: 0 2px 36px -6px rgba(57,55,73,0.25);
	border: 0;
	&.border-top {
		box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 $green;
	}
}

.card-header,
.card-footer {
	border-color: rgba(0, 0, 0, 0.03);
}
.card-title {
	font-size: 1.1rem;
	margin-bottom: 1.5rem;
}
.card-img-overlay {
	* {
		position: relative;
		z-index: 1;
	}
	&:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0.36);
		z-index: 0;
	}
	.separator {
		width: 35px;
		margin: auto;
	}
	.card-footer {
		position: absolute;
		bottom: 16px;
		left: 20px;
		border: 0;
		background: transparent;
		[class^='i-'] {
			font-size: 0.875rem;
			vertical-align: text-bottom;
		}
	}
}

.card-icon {
	.card-body {
		padding: 2rem 0.5rem;
	}
	[class^='i-'] {
		font-size: 32px;
	}
	[class^='i-'],
	.lead {
		color: $primary;
	}
}

.card-icon-big {
	.card-body {
		padding: 2rem 0.5rem;
	}
	[class^='i-'] {
		font-size: 48px;
	}
	[class^='i-'] {
		color: rgba($primary, 0.6);
	}
}

.card-icon-bg {
	position: relative;
	z-index: 1;
	.card-body {
		// padding: 2rem .5rem;
		display: flex;
		.content {
			margin: auto;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			max-width: 70px;
		}
	}
	[class^='i-'] {
		font-size: 4rem;
		// position: absolute;
		// left: -26px;
		// top: calc(50% - 39px);
		color: rgba($light, 0.28);
		// z-index: -1;
	}
	.lead {
		line-height: 1;
	}
}
@each $name, $value in $theme-colors {
	.card-icon-bg-#{$name} {
		[class^='i-'] {
			color: rgba($value, 0.28);
		}
	}
}

.card-profile-1 {
	.avatar {
		width: 80px;
		height: 80px;
		overflow: hidden;
		margin: auto;
		border-radius: 50%;
	}
}

.card-ecommerce-1 {
	.card-body {
		[class^='i-'] {
			display: block;
			font-size: 78px;
			color: $primary;
		}
	}
}
.card-ecommerce-2 {
	.row {
		margin: 0;
	}
	.card-action,
	.col {
		padding: 1rem;
	}
	.card-action {
		position: relative;
		display: flex;
		align-items: center;
		.icon {
			font-size: 22px;
			height: 24px;
			display: inline-block;
			width: 24px;
			line-height: 24px;
			margin: 0 8px;
			cursor: pointer;
		}
		&:before {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 1px;
			height: 100%;
			background: rgba($foreground, 0.1);
		}
	}
}

.card-ecommerce-3 {
	.card-img-left {
		height: 220px;
		object-fit: cover;
	}
}

.card-socials-simple {
	a {
		display: inline-block;
		padding: 4px;
	}
}

.card-zoom-in {
	position: relative;
	background-color: white;
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card-zoom-in:after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	border-radius: 5px;
	opacity: 0;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card-zoom-in:hover {
	transform: scale(1.2);
}

.card-zoom-in:hover:after {
	opacity: 1;
}

.card {
	&--filter {
		background-color: rgba(237, 237, 237, 0.5);
		border: #ededed 1px solid;
		padding: 20px 20px 0;

		&--header {
			margin-bottom: 24px;

			nav {
				button {
					margin-right: 8px;
				}
			}
		}

		&--item {
			margin: 0;
			padding: 0 0 20px;

			dt {
				color: #687188;
				font-size: 14px;
				font-weight: 500;
				line-height: normal;
				margin-bottom: 8px;
				text-transform: uppercase;
			}

			dd {
				color: #848484;

				.checkbox-list {
					padding-top: 0;

					.mat-list-option {
						color: #848484;
					}
				}
			}
		}
	}

	i {
		vertical-align: text-bottom;
		font-size: 16px;
	}

	h1 {
		font-size: 20px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.3;
		letter-spacing: normal;
		color: #6c6c6c;
	}
}

.data-filled {
	font-family: 'Open Sans';
	position: relative;

	&--graph {
		position: relative;

		&:before {
			background: linear-gradient(
				to right,
				rgba($primary, 0.1) 0%,
				rgba($primary, 0.1) 20%,
				rgba($primary, 0.25) 20%,
				rgba($primary, 0.25) 40%,
				rgba($primary, 0.5) 40%,
				rgba($primary, 0.5) 60%,
				rgba($primary, 0.75) 60%,
				rgba($primary, 0.75) 80%,
				rgba($primary, 1) 80%,
				rgba($primary, 1) 100%
			);
			content: '';
			display: block;
			height: 8px;
			left: 0;
			position: absolute;
			top: 20px;
			width: 100%;
		}

		ul {
			height: 28px;
			margin: 0;
			padding: 0;
			position: relative;

			li {
				background: #cacbcd;
				height: 48px;
				list-style: none;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 0;
				width: 2px;

				&.line-1 {
					left: 0;
				}
				&.line-2 {
					left: 20%;
				}
				&.line-3 {
					left: 40%;
				}
				&.line-4 {
					left: 60%;
				}
				&.line-5 {
					left: 80%;
				}
				&.line-6 {
					right: 0;
				}
			}
		}
	}

	&--label {
		overflow: hidden;
		position: relative;

		dl {
			float: left;
			text-align: center;

			dt {
				color: #687188;
				font-size: 11px;
				font-weight: normal;
				margin-bottom: 8px;
			}

			dd {
				padding-top: 32px;
				position: relative;
				transition: all 0.3s ease-in-out;

				&:before {
					border-left: dotted 1px #b3b3b3;
					content: '';
					display: block;
					height: 90%;
					left: 50%;
					margin-left: -1px;
					position: absolute;
					top: 0;
					width: 1px;
					z-index: 1;
				}

				span {
					background: #fff;
					border-radius: 4px;
					border: solid 1px #ededed;
					color: #b3b3b3;
					cursor: pointer;
					display: inline-block;
					font-size: 12px;
					font-weight: normal;
					padding: 12px 16px 8px;
					position: relative;
					z-index: 2;

					strong {
						color: #131313;
						display: block;
						font-size: 24px;
						font-weight: bold;
						line-height: 22px;
					}
				}

				&:hover {
					transform: translateY(-5px);
				}
			}

			&.label-1,
			&.label-2,
			&.label-3,
			&.label-4,
			&.label-5 {
				width: 20%;
			}
		}
	}
}
