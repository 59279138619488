@use "../../../node_modules/@angular/material" as mat;
@include mat.core();

$my-primary: mat.define-palette(mat.$blue-palette, 900);
$my-accent: mat.define-palette(mat.$pink-palette, 600);
$my-warning: mat.define-palette(mat.$red-palette, 900);

$my-primary-dark: mat.define-palette(mat.$blue-palette, 900);
$my-accent-dark: mat.define-palette(mat.$pink-palette, 600);
$my-warning-dark: mat.define-palette(mat.$red-palette, 900);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
   warning: $my-warning
 ),
 density: 0,
));

$my-theme-dark: mat.define-dark-theme((
 color: (
   primary: $my-primary-dark,
   accent: $my-accent-dark,
   warning: $my-warning-dark
 ),
 density: 0,
));

mat-form-field {
  width: 100%; /* ou ajustar conforme a necessidade */
}

input.mat-input-element {
  height: auto; /* Verifique se há algum valor fixo de altura que esteja causando o problema */
}

@include mat.all-component-themes($my-theme);

.dark-theme {
    @include mat.all-component-themes($my-theme-dark);
}


