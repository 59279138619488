.nav-tabs {
	border: 0;
	.nav-item {
		.nav-link {
			border: 0;
			padding: 1rem;
			&:not(.disabled) {
				color: inherit;
			}
			&.active {
				border-bottom: 2px solid $primary;
				background: rgba($color: $primary, $alpha: 0.1);
			}
		}
	}
}

.tab-content {
	padding: 1rem;
}
ngb-tabset {
	&.nav-center {
		.nav-tabs {
			justify-content: center !important;
			margin-bottom: 1.5rem;
		}
	}
}

ngb-tabset.p-0 {
	.tab-content {
		padding: 1rem 0;
	}
}
