
$fa-font-path: '../webfonts';

@import "../lib/fontawesome/scss/fontawesome.scss";
@import "../lib/fontawesome/scss/solid.scss";
@import "../lib/fontawesome/scss/brands.scss";
@import "../lib/fontawesome/scss/light.scss";
@import "../lib/fontawesome/scss/regular.scss";

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,600,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:wght@400;700;900&display=swap');

@import 'variables';
@import '../../../node_modules/@swimlane/ngx-datatable/index.css';
@import '../../../node_modules/@swimlane/ngx-datatable/themes/material.css';
@import '../../../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import '../../../node_modules/ngx-toastr/toastr.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~highlight.js/styles/github.css';
@import '~ladda/dist/ladda.min.css';
@import '~dragula/dist/dragula.css';
// @import '~@fullcalendar/common/main.css';
// @import '~@fullcalendar/daygrid/main.css';

// @import "../../../node_modules/bootstrap/scss/bootstrap";
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
// @import 'bootstrap-rtl.scss';

@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import 'globals/globals';

@import 'material/custom.scss';

.material-icons {
	font-family: 'Material Icons';
}

.mat-form-field, .mat-checkbox, .mat-select, .mat-option, .mat-radio-button {
	font-family: 'Open Sans', sans-serif !important;
	letter-spacing: 0px;
}

button,
a,
.dropdown-item {
	outline: none !important;
}

// FullCalendar variables
.card-select {
    border: 1px solid #DAD7D3;
    background: #E3E1DE;

    .card-body {
        padding: 30px 20px 50px;
    }
}
.card-schedule {
    margin-top: -30px;
    .card-body {
        padding: 21px 30px 12px;
    }
}

.daterangepicker {
  .ranges li.active {
      background-color: #57A1A1!important;
  }
  td {
      &.in-range {
          background-color: lighten(#57A1A1, 70%)!important;
      }
      &.end-date.active,&.start-date.active {
          background-color: #57A1A1!important;
      }
  }

}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #aeb0b3 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #aeb0b3;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

// .popover-free-time {
//     .info {


//         .description {
//             display: inline-block;
//             width: 256px;
//         }
//         .icon {
//             display: inline-block;
//             color: #3A9BD9;
//                 font-size: 16px;
//         }
//     }
// }
